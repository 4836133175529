import './services.product';
const BUTTON_DISPLAY_TYPE = {
  DEPEND_ON_STOCK: 'depend_on_stock',
  PREORDER: 'preorder',
  ADD_TO_CART: 'add_to_cart',
};

app.service('productPreorderService', [
  '$filter',
  'mainConfig',
  'slFeatureService',
  'productService',
  function ($filter, mainConfig, slFeatureService, productService) {
    function getPreorderLimit(rootProduct, childProduct) {
      const product = childProduct || rootProduct;
      return slFeatureService.hasFeature('preorder_limit') &&
        rootProduct.is_preorder
        ? product.preorder_limit
        : 0;
    }
    function isPreorderWithQuantity(productStockInfo, addItemQuantity) {
      const { quantity, is_preorder, cart_quantity, unlimited_quantity } =
        productStockInfo;
      if (!slFeatureService.hasFeature('preorder_limit')) {
        return is_preorder;
      }
      if (unlimited_quantity || !is_preorder) {
        return false;
      }
      const leftStocks = quantity - cart_quantity;
      return leftStocks < addItemQuantity;
    }
    function isPreorderOrderable(productStockInfo) {
      if (!slFeatureService.hasFeature('preorder_limit')) {
        return productStockInfo.is_preorder && productStockInfo.quantity > 0;
      }
      const preorderLimit = productStockInfo.is_preorder
        ? productStockInfo.preorder_limit
        : 0;
      return (
        productStockInfo.quantity > 0 ||
        preorderLimit === productService.PREORDER_UNLIMITED_AMOUNT ||
        preorderLimit + productStockInfo.quantity > 0
      );
    }
    function isPreorderProduct(productStockInfo, addItemQuantity) {
      if (!slFeatureService.hasFeature('preorder_limit')) {
        return productStockInfo.is_preorder;
      }
      if (!productStockInfo.is_preorder) {
        return false;
      }
      const preorderText =
        mainConfig.merchantData?.product_setting
          ?.preorder_add_to_cart_button_display;
      switch (preorderText) {
        case BUTTON_DISPLAY_TYPE.ADD_TO_CART:
          return false;
        case BUTTON_DISPLAY_TYPE.PREORDER:
          return true;
        case BUTTON_DISPLAY_TYPE.DEPEND_ON_STOCK:
        default:
          return isPreorderWithQuantity(productStockInfo, addItemQuantity);
      }
    }
    function getPerorderButtonTextWithSettings(
      productStockInfo,
      addItemQuantity,
    ) {
      const displayType =
        mainConfig.merchantData?.product_setting
          ?.preorder_add_to_cart_button_display;
      const addToCartText = $filter('translate')('product.add_to_cart');
      const preorderText = $filter('translate')(
        'products.quick_cart.preorder_now',
      );
      switch (displayType) {
        case BUTTON_DISPLAY_TYPE.ADD_TO_CART:
          return addToCartText;
        case BUTTON_DISPLAY_TYPE.PREORDER:
          return preorderText;
        case BUTTON_DISPLAY_TYPE.DEPEND_ON_STOCK:
        default:
          return isPreorderWithQuantity(productStockInfo, addItemQuantity)
            ? preorderText
            : addToCartText;
      }
    }
    function getAddToCartButtonText({
      productStockInfo,
      addItemQuantity,
      isBuyNowButtonEnabled,
      availableType = '',
    }) {
      if (availableType === 'coming_soon') {
        return $filter('translate')('product.coming_soon');
      }
      if (
        !slFeatureService.hasFeature('preorder_limit') &&
        productStockInfo.is_preorder &&
        !isBuyNowButtonEnabled
      ) {
        return $filter('translate')('products.quick_cart.preorder_now');
      }
      if (
        slFeatureService.hasFeature('preorder_limit') &&
        productStockInfo.is_preorder
      ) {
        return getPerorderButtonTextWithSettings(
          productStockInfo,
          addItemQuantity,
        );
      }
      if (availableType === 'available_time_over') {
        return $filter('translate')('product.available_time_over');
      }
      if (availableType === 'buy_now') {
        return $filter('translate')('product.buy_now');
      }
      if (availableType === 'buy_together' && !isBuyNowButtonEnabled) {
        return $filter('translate')('product.buy_together');
      }
      return $filter('translate')('product.add_to_cart');
    }
    return {
      isPreorderProduct,
      isPreorderWithQuantity,
      getAddToCartButtonText,
      isPreorderOrderable,
      getPreorderLimit,
    };
  },
]);
