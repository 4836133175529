import './services.feature';
const COOKIE_CONSENT_COOKIE_KEY = 'cookie_consent_preferences';
const COOKIE_CONSENT_TYPES = [
  'necessary',
  'statistical',
  'marketing',
  'functionality',
];

app.service('cookieConsentService', [
  'mainConfig',
  'featureService',
  '$cookies',
  function cookieConsentService(mainConfig, featureService, $cookies) {
    const hasCookieConsentKey = featureService.hasFeature('cookie_consent');
    const cookieConsentSetting =
      mainConfig.themeSettings &&
      mainConfig.themeSettings.enable_gdpr_cookies_banner;
    const cookieConsentEnabled = hasCookieConsentKey && !!cookieConsentSetting;

    /**
     * Restore user preferences from cookies.
     * Only restore if:
     * 1. Cookie consent feature is enabled by merchant, and
     * 2. Value stored in cookie is an array
     * @returns [string[]|undefined]
     */
    const restorePreferences = () => {
      try {
        if (!cookieConsentEnabled) {
          return;
        }

        const cookieString = $cookies.get(COOKIE_CONSENT_COOKIE_KEY);
        if (!cookieString) {
          return;
        }

        const preferences = JSON.parse(cookieString);
        if (Array.isArray(preferences)) {
          // Return only string values
          return preferences.filter((type) => typeof type === 'string');
        }
      } catch (e) {
        console.error('Cookie consent preferences cannot be restored', e);
      }
    };

    /**
     * Whether cookie consent feature is enabled by merchant.
     * It is considered to be enabled if:
     * 1. Merchant has the feature key, and
     * 2. Merchant has enabled the feature in theme setting
     * @type {boolean}
     */
    this.enabled = cookieConsentEnabled;

    /**
     * User provided cookie consent preferences
     * @type {string[]|undefined}
     */
    this.preferences = restorePreferences();

    /**
     * Save cookie consent preferences
     * NOTE: shouldDeleteAllCookie will lead to all cookies being deleted
     * @param {string[]} newPreferences
     * @param {boolean} shouldDeleteAllCookie
     * @returns {boolean}
     */
    this.savePreferences = (newPreferences, shouldDeleteAllCookie = false) => {
      try {
        // Only save preferences if it's an array
        if (Array.isArray(newPreferences)) {
          // Update preferences list in local state
          this.preferences = newPreferences;

          // Set cookie expiry date to 1 year later
          const cookieExpiryDate = new Date();
          cookieExpiryDate.setFullYear(cookieExpiryDate.getFullYear() + 1);
          cookieExpiryDate.setHours(5);

          if (shouldDeleteAllCookie) {
            this.deleteAllCookie();
          }

          // Store preferences list in cookies
          $cookies.put(
            COOKIE_CONSENT_COOKIE_KEY,
            JSON.stringify(newPreferences),
            { expires: cookieExpiryDate },
          );

          return true;
        }
      } catch (e) {
        console.error('Cookie consent preferences cannot be saved', e);
      }

      return false;
    };

    /**
     * Check whether provided cookie type can be used
     * @param {string} type
     * @returns {boolean}
     */
    this.canUseCookie = (type) => {
      try {
        // Allow all cookies usage unless merchant enabled cookie consent feature
        if (!this.enabled) {
          return true;
        }

        // Strictly necessary cookies can be used before any user consent,
        // ref: https://gdpr.eu/cookies/
        if (type === 'necessary') {
          return true;
        }

        // Validate provided cookie type,
        // then validate its presence in user consent
        return (
          COOKIE_CONSENT_TYPES.indexOf(type) > -1 &&
          Array.isArray(this.preferences) &&
          this.preferences.indexOf(type) > -1
        );
      } catch (e) {
        console.error('Cookie consent preferences cannot be checked', e);

        // Fallback to true on error
        return true;
      }
    };

    /**
     * Get value from cookie,
     * only when provided cookie type can be used
     * @param {string} type
     * @param {string} key
     * @returns {string|undefined}
     */
    this.getConsentedCookie = (type, key) => {
      if (this.canUseCookie(type)) {
        return $cookies.get(key);
      }
      return undefined;
    };

    /**
     * Put value into cookie,
     * only when provided cookie type can be used
     * @param {string} type
     * @param {string} key
     * @param {any} value
     * @returns {boolean}
     */
    this.putConsentedCookie = (type, key, value) => {
      if (this.canUseCookie(type)) {
        $cookies.put(key, value);
        return true;
      }
      return false;
    };

    /**
     * Remove value from cookie,
     * only when provided cookie type can be used
     * @param {string} type
     * @param {string} key
     * @returns {boolean}
     */
    this.removeConsentedCookie = (type, key) => {
      if (this.canUseCookie(type)) {
        $cookies.remove(key);
        return true;
      }
      return false;
    };

    /**
     * Remove all cookies
     */
    this.deleteAllCookie = () => {
      let allCookies = $cookies.getAll();
      const hostSeparated = window.location.host.split('.');
      let domainArray = [
        window.location.host,
        hostSeparated.slice(-2).join('.'),
      ];
      domainArray = domainArray.concat(domainArray.map((d) => `.${d}`));
      Object.entries(allCookies).forEach(([key]) => {
        domainArray.forEach((domain) => {
          $cookies.remove(key, { path: '/', domain });
        });
      });
    };

    /**
     * Get all cookies consent
     */
    this.getAllCookieConsents = () => {
      return COOKIE_CONSENT_TYPES.reduce((acc, type) => {
        acc[type] = this.canUseCookie(type);
        return acc;
      }, {});
    };
  },
]);
