app.service('pnotifyService', [
  function () {
    const notify = function (title, styleParams, onClick) {
      const notice = new PNotify({
        title: title,
        addclass: styleParams.customClass || 'success',
        animate: styleParams.animate || {
          animate: true,
          in_class: 'fadeInDown',
          out_class: 'fadeOut',
        },
        delay: styleParams.delay || 4000,
        icon: styleParams.icon || 'fa fa-check-circle',
      });

      if (onClick) {
        notice.container[0].style.cursor = 'pointer';
        notice.container[0].addEventListener('click', onClick);
      }

      return notice;
    };

    return { notify };
  },
]);
