import './services.product-preorder';
import './services.product';
import './services.money-services';
import { sumBy } from 'lodash-es';

app.service('productSetService', [
  'mainConfig',
  'slFeatureService',
  '$filter',
  'moneyService',
  'productService',
  'productPreorderService',
  function (
    mainConfig,
    slFeatureService,
    $filter,
    moneyService,
    productService,
    productPreorderService,
  ) {
    function calculateProductSetStock(productSetData) {
      var stocks = productSetData
        .filter(function (productData) {
          return (
            !productData.childProduct.unlimited_quantity &&
            !productData.childProduct.out_of_stock_orderable
          );
        })
        .map(function (productData) {
          return productData.childVariation
            ? productData.childVariation.quantity
            : productData.childProduct.quantity;
        });
      return Math.min.apply(null, stocks);
    }

    function generateProductSetParams(productSetData) {
      return productSetData.map(function (childProductData) {
        return {
          child_product_id: childProductData.childProduct._id,
          child_variation_id:
            childProductData.childVariation &&
            childProductData.childVariation.key,
        };
      });
    }

    function checkChildProductStock(product) {
      if (product.unlimited_quantity) return true;
      if (productService.isOutOfStockOrderable(product)) return true;
      const hasVariation = product.field_titles?.length > 0;
      if (hasVariation) {
        const singleVariantAvailable = product.variations.some((variation) => {
          const preorderLimit = productPreorderService.getPreorderLimit(
            product,
            variation,
          );
          const totalVariantQuantity = variation.quantity + preorderLimit;
          return (
            totalVariantQuantity >= (product.bundle_set?.min_quantity || 1)
          );
        });
        const sumQuantity = sumBy(product.variations, (variation) => {
          const preorderLimit = productPreorderService.getPreorderLimit(
            product,
            variation,
          );
          return variation.quantity + preorderLimit;
        });
        const totalQuantityAvailable =
          sumQuantity >= (product.bundle_set?.min_quantity || 1);
        return totalQuantityAvailable || singleVariantAvailable;
      }
      const preorderLimit = productPreorderService.getPreorderLimit(product);
      return (
        product.quantity + preorderLimit >=
        (product.bundle_set?.min_quantity || 1)
      );
    }

    function roundToOne(number) {
      return Math.round(number / 10) * 10;
    }

    function getCheckoutPrice({ product, basketCartItems }) {
      if (product.bundle_set.price_type === 'fixed_amount') {
        return product.price_sale;
      }

      let totalPrice = basketCartItems.reduce((acc, curr) => {
        const pricingTarget =
          !curr.same_price && curr.variationSelected
            ? curr.variationSelected
            : curr;
        return (
          acc +
          (pricingTarget.price_sale?.cents || pricingTarget.price?.cents || 0) *
            curr.quantity
        );
      }, 0);
      let discountAmount = 0;
      if (product.bundle_set.price_type === 'discount_percentage') {
        discountAmount = (totalPrice * product.bundle_set.discount_value) / 100;
        discountAmount = moneyService.bankersRound(discountAmount);
      } else if (product.bundle_set.price_type === 'discount_amount') {
        discountAmount = moneyService.dollarsToCents(
          product.bundle_set.discount_value,
          mainConfig.merchantData.base_currency.iso_code,
        );
      }
      totalPrice -= discountAmount;
      totalPrice = totalPrice > 0 ? totalPrice : 0;
      if (slFeatureService.hasFeature('rounding_to_one_decimal')) {
        totalPrice = roundToOne(totalPrice);
      }
      return moneyService.toMoney({
        cents: totalPrice,
      });
    }

    function getChildVariationName(variation) {
      return variation?.fields
        .map((field) => $filter('translateModel')(field.name_translations))
        .join(', ');
    }

    function getSelectedItemDetail(selectedChildProduct, item) {
      var childProduct = item.product.child_products.find((childProduct) => {
        return childProduct._id === selectedChildProduct.child_product_id;
      });
      var childVariation =
        selectedChildProduct.child_variation_id &&
        childProduct.child_variations?.find((childVariation) => {
          return childVariation.key === selectedChildProduct.child_variation_id;
        });

      return {
        childProductName: $filter('translateModel')(
          childProduct.title_translations,
        ),
        childVariationName:
          childVariation && getChildVariationName(childVariation),
      };
    }

    return {
      calculateProductSetStock: calculateProductSetStock,
      generateProductSetParams: generateProductSetParams,
      isProductSetEnabled: function (product) {
        return (
          product.type === 'product_set' &&
          slFeatureService.hasFeature('product_set')
        );
      },
      isProductSetRevampEnabled: function (product) {
        return (
          product.type === 'product_set' &&
          slFeatureService.hasFeature('product_set_revamp')
        );
      },
      checkMinQuantity: function (childProducts) {
        return childProducts.every(checkChildProductStock);
      },
      checkChildProductStock,
      getChildVariationName,
      getSelectedItemDetail,
      getCheckoutPrice,
    };
  },
]);
