const CHECKOUT_EVENTS = {
  CART: {
    ADDONS: {
      RELOAD: 'checkout.cart.addons.reload',
    },
    COLLAPSE_BLOCK: {
      TOGGLE_STATUS: 'checkout.cart.collapse_block.toggle_status',
    },
    COUPON: {
      REMOVE: 'checkout.cart.coupon.remove',
    },
    CONTENT: {
      LOADED: 'checkout.cart.content.loaded',
    },
    DELIVERY: {
      RELOAD: 'checkout.cart.delivery.reload',
      CHANGING: 'checkout.cart.delivery.changing',
      CHANGED: 'checkout.cart.delivery.changed',
      UPDATE_FEE: 'checkout.cart.delivery.update.fee',
      RECALCULATE_FEE: 'checkout.cart.delivery.recalculate.fee',
    },
    DESTINATION: {
      RELOAD: 'checkout.cart.destination.reload',
      CHANGING: 'checkout.cart.destination.changing',
      CHANGED: 'checkout.cart.destination.changed',
    },
    EMPTY: 'checkout.cart.empty',
    FORM: {
      RELOAD: 'checkout.cart.form.reload',
      CHANGING: 'checkout.cart.form.changing',
      CHANGED: 'checkout.cart.form.changed',
      LOADED: 'checkout.cart.form.loaded',
      RERENDER: 'checkout.cart.form.rerender',
    },
    ITEM: {
      UPDATED: 'checkout.cart.item.updated',
      REMOVED: 'checkout.cart.item.removed',
    },
    ITEMS: {
      RELOAD: 'checkout.cart.items.reload',
      CHANGING: 'checkout.cart.items.changing',
      CHANGED: 'checkout.cart.items.changed',
      VALIDATED: 'checkout.cart.items.validated',
    },
    PROMOTIONS: {
      RELOAD: 'checkout.cart.promotions.reload',
      CHANGED: 'checkout.cart.promotions.changed',
    },
    SUMMARY: {
      RELOAD: 'checkout.cart.summary.reload',
      CHANGED: 'checkout.cart.summary.changed',
      LOADED: 'checkout.cart.summary.loaded',
      UPDATED: 'checkout.cart.summary.updated',
    },
    STEP: {
      NEXT: 'checkout.cart.step.next',
    },
    NOTHING: {
      CHANGED: 'checkout.cart.nothing.changed',
    },
  },
};

export { CHECKOUT_EVENTS };
