app.service('wishlistService', [
  '$http',
  function ($http) {
    this.getList = function (params) {
      return $http({
        method: 'GET',
        url: '/api/wish_list_items',
        params: params,
      });
    };

    this.removeItem = function (params) {
      return $http({
        method: 'DELETE',
        url: '/api/wish_list_items',
        params: params,
      });
    };

    this.addItem = function (data) {
      return $http({
        method: 'POST',
        url: '/api/wish_list_items',
        data: data,
      });
    };

    this.updateItem = function (data) {
      return $http({
        method: 'PUT',
        url: '/api/wish_list_items/' + data.id,
        data: data,
      });
    };
  },
]);
