import { isEmpty, isNil } from 'lodash-es';

// NOTE: sku may be null, undefined, or '' in different cases
const getSku = (_id, product_sku, variation, settings) => {
  // Note: Logic here must match product_sku from fb_feed_helper.rb
  if (!isNil(settings)) {
    if (settings.single_variation && settings.unique_ids) {
      return _id;
    }
    if (settings.single_variation && !settings.unique_ids) {
      return product_sku || _id;
    }
    if (settings.unique_ids && !isEmpty(variation)) {
      return `${_id}:${variation.key}`;
    }
    if (settings.unique_ids) {
      return _id;
    }
  }
  if (!isEmpty(variation)) {
    if (!isEmpty(variation.sku)) {
      return variation.sku;
    }
    if (!isEmpty(product_sku)) {
      return `${product_sku}:${variation.key}`;
    }
    return `${_id}:${variation.key}`;
  }
  if (!isEmpty(product_sku)) {
    return product_sku;
  }
  return _id;
};

export default getSku;
