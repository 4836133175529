export const EVENT_NAME_FOR_USER_FLOW = {
  MemberSignUpFormShow: 'MemberSignUpFormShow',
  MemberSignUpFormSubmit: 'MemberSignUpFormSubmit',
  ThirdPartySignUpClick: 'ThirdPartySignUpClick',
  ThirdPartyLoginClick: 'ThirdPartyLoginClick',
  MemberLoginClick: 'MemberLoginClick',
  SMSVerificationCodeSend: 'SMSVerificationCodeSend',
  SMSVerificationFormShow: 'SMSVerificationFormShow',
  SMSVerificationSendButtonClick: 'SMSVerificationSendButtonClick',
  SMSVerificationSubmit: 'SMSVerificationSubmit',
  RecaptchaVerificationStart: 'RecaptchaVerificationStart',
  RecaptchaVerificationComplete: 'RecaptchaVerificationComplete',
  MemberSignUpInfoFormShow: 'MemberSignUpInfoFormShow',
  MemberSignUpInfoSubmit: 'MemberSignUpInfoSubmit',
  MemberSignUpSucceed: 'MemberSignUpSucceed',
  MemberLoginFormShow: 'MemberLoginFormShow',
  MemberLoginSucceed: 'MemberLoginSucceed',
  UpdateMemberInfoFormShow: 'UpdateMemberInfoFormShow',
  UpdateMemberInfoSucceed: 'UpdateMemberInfoSucceed',
  CartMemberLoginClick: 'CartMemberLoginClick',
  CartThirdPartySignUpClick: 'CartThirdPartySignUpClick',
};

export const EVENT_CATEGORY_FOR_USER_FLOW = {
  SignUp: 'SignUp',
  MemberLogin: 'MemberLogin',
  UserVerification: 'UserVerification',
  ProfileUpdate: 'ProfileUpdate',
};

export const VALUE_FOR_USER_FLOW = {
  email: 'email',
  mobile: 'mobile',
  email_and_mobile: 'email_and_mobile',
  facebook: 'facebook',
  google: 'google',
  line: 'line',
  initial_signup: 'initial_signup',
  signup_resend: 'signup_resend',
  member_info_update: 'member_info_update',
  merchant: 'merchant',
  shopline: 'shopline',
  email_only: 'email_only',
  mobile_only: 'mobile_only',
  sms: 'sms',
  none: 'none',
};

export const PROPERTY_FOR_USER_FLOW = {
  sign_up_option: 'sign_up_option',
  third_party_sign_up_option: 'third_party_sign_up_option',
  third_party_login_option: 'third_party_login_option',
  sms_verification: 'sms_verification',
  accept_marketing: 'accept_marketing',
  purpose: 'purpose',
  verification_type: 'verification_type',
  set_up_by: 'set_up_by',
  login_option: 'login_option',
  update_info: 'update_info',
  need_verification: 'need_verification',
  pass_time: 'pass_time',
};

export const EVENT_NAME_FOR_ARCHIVE_ORDERS = {
  ORDER_NUMBER_LINK_CLICK: 'OrderNumberLinkClick',
};

export const EVENT_POSITION_FOR_ARCHIVE_ORDERS = {
  MEMBER_CENTER_CREDIT_HISTORY: 'membercenter_credit_history',
  MEMBER_CENTER_POINT_HISTORY: 'membercenter_point_history',
};

export const EVENT_SCOPE_FOR_ARCHIVE_ORDERS = {
  CRM: 'CRM',
};

export const EVENT_CREATED_AT_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const EVENT_NAME_FOR_SUBSCRIPTION = {
  MEMBER_SUBSCRIPTION_BUTTON_CLICK: 'MemberSubscriptionButtonClick',
  MEMBER_SUBSCRIPTION_POPUP_CLICK: 'MemberSubscriptionPopupClick',
};

export const EVENT_CATEGORY_FOR_SUBSCRIPTION = {
  MEMBER_SUBSCRIPTION: 'MemberSubscription',
};

export const EVENT_PROPERTY_FOR_SUBSCRIPTION = {
  SUBSCRIPTION_TYPE: 'subscription_type',
  SUBSCRIPTION_PATH: 'subscription_path',
  THIRD_PARTY: 'third_party',
};

export const EVENT_PROPERTY_VALUE_FOR_SUBSCRIPTION = {
  // SUBSCRIPTION_TYPE
  PROMOTION_NOTIFICATION: 'promotion_notification',
  ORDER_NOTIFICATION: 'order_notification',
  // SUBSCRIPTION_PATH
  SIGN_UP_PAGE: 'sign_up_page',
  MEMBER_CENTER: 'member_center',
  ORDER_CONFIRM_PAGE: 'order_confirm_page',
  ORDER_PAYMENT_SLIPS_UPLOAD_PAGE: 'order_payment_slips_upload_page',
  // THIRD_PARTY
  FACEBOOK: 'facebook',
  LINE: 'line',
};

const EVENT_NAME_FOR_TAG_PRODUCTS_PAGE = {
  FIXED_RECOMMENDATION_BAR_CLICK: 'AttributeTagEntranceClick',
};

export const USER_ACTION_TYPES = {
  CHECKOUT: 'Checkout',
  ADD_ITEM: 'AddItem',
  UPDATE_ITEM: 'UpdateItem',
  REMOVE_ITEM: 'RemoveItem',
};

export const TRACKER_GENERAL_EVENT_TYPES = {
  REGISTRATION: 'TRACK.REGISTRATION',
  SEARCH: 'TRACK.SEARCH',
  VIEW_CONTENT: 'TRACK.VIEW_CONTENT',
  ADD_TO_CART: 'TRACK.ADD_TO_CART',
  REMOVE_FROM_CART: 'TRACK.REMOVE_FROM_CART',
  START_CHECKOUT: 'TRACK.START_CHECKOUT',
  INITIATE_CHECKOUT: 'TRACK.INITIATE_CHECKOUT',
  ADD_SHIPPING: 'TRACK.ADD_SHIPPING',
  ADD_BILLING: 'TRACK.ADD_BILLING',
  PURCHASE: 'TRACK.PURCHASE',
  LOAD_CART_PAGE: 'TRACK.LOAD_CART_PAGE',
  LOAD_CHECKOUT_PAGE: 'TRACK.LOAD_CHECKOUT_PAGE',
  PRODUCT_CLICK: 'TRACK.PRODUCT_CLICK',
  ADD_TO_WISHLIST: 'TRACK.ADD_TO_WISHLIST',
  REMOVE_FROM_WISHLIST: 'TRACK.REMOVE_FROM_WISHLIST',
  ...EVENT_NAME_FOR_USER_FLOW,
  ...EVENT_NAME_FOR_ARCHIVE_ORDERS,
  ...EVENT_NAME_FOR_SUBSCRIPTION,
  ...EVENT_NAME_FOR_TAG_PRODUCTS_PAGE,
};

// @TODO: integration with original events
export const ONE_PAGE_STORE_TRACKER_TYPE = {
  AddItem: 'AddItem',
  CartImpression: 'CartImpression',
  Checkout: 'Checkout',
  CheckoutInput: 'CheckoutInput',
  MiniCartButtonClick: 'MiniCartButtonClick',
  OrderCheckoutButtonClick: 'OrderCheckoutButtonClick',
  ProductClick: 'ProductClick',
  ProductImpression: 'ProductImpression',
  RemoveItem: 'RemoveItem',
  UpdateItem: 'UpdateItem',
  View: 'View',
};

/**
 * Tracker event localStorage key
 *
 * The key stored in localStorage can be seen by developers. Use "sl" prefix
 */
export const TRACKER_LOCAL_STORAGE_KEYS = {
  purchaseEventTriggered: 'sl_purchase_event_triggered',
};

export const CART_REVAMP_TRACKER_TYPE = {
  CartPageLoad: 'CartPageLoad',
  AddAddOnItem: 'AddAddOnItem',
  UpdateItem: 'UpdateItem',
  RemoveItem: 'RemoveItem',
  GoToCheckout: 'GoToCheckout',
  CouponCenterLinkClick: 'CouponCenterLinkClick',
  PaymentPromoOptionClick: 'PaymentPromoOptionClick',
  PaymentPromoOptionShow: 'PaymentPromoOptionShow',
  PromotionReminderClick: 'PromotionReminderClick',
  PromotionReminderShow: 'PromotionReminderShow',
  AddonAllListClick: 'AddonAllListClick',
  AddonAllListShow: 'AddonAllListShow',
};
