app.service('featureService', [
  '$rootScope',
  'mainConfig',
  function ($rootScope, mainConfig) {
    this.hasBetaFeature = function (key) {
      if (!mainConfig.merchantData.beta_feature_keys) return false;
      return mainConfig.merchantData.beta_feature_keys.indexOf(key) != -1;
    };

    this.hasRolloutFeature = function (key) {
      return _.contains(mainConfig.merchantData.rollout_keys || [], key);
    };

    this.hasFeature = function (key) {
      return (
        _.contains(mainConfig.merchantData.beta_feature_keys || [], key) ||
        _.contains(mainConfig.merchantData.rollout_keys || [], key)
      );
    };

    this.getSetting = function (key, defaultValue) {
      if (angular.isUndefined(defaultValue)) defaultValue = 0;

      var currentBasePlan = this.getBasePlan(
        mainConfig.merchantData.current_plan_key,
      );

      if (angular.isUndefined(mainConfig.features['plans'][currentBasePlan]))
        return defaultValue;
      if (
        angular.isUndefined(mainConfig.features['plans'][currentBasePlan][key])
      )
        return defaultValue;
      return mainConfig.features['plans'][currentBasePlan][key];
    };

    this.getBasePlan = function (value) {
      return value.split('_')[0];
    };
  },
]);
