app.service('categoryFilterService', [
  'mainConfig',
  function (mainConfig) {
    var themeKey = mainConfig.merchantData.current_theme_key;
    var drawerTypes = ['king', 'chic', 'ultra_chic'];
    return {
      isDrawerType: function () {
        return drawerTypes.includes(themeKey);
      },
      getFilterTypes: function () {
        return [
          'brand[]',
          'color[]',
          'size[]',
          'material[]',
          'tag[]',
          'min_price',
          'max_price',
        ];
      },
    };
  },
]);
