/**
 * TODO:
 * 1. extract the LINE API part from benchat API CRUD to another service
 * 2. Modify API to support the real bulk update
 */
import axios from 'axios';
import { SUBSCRIPTION_STATUS_TYPE } from '../constants/benchat';

export const generateRandomNumber = () =>
  Math.floor(Math.random() * 10000000000000000);

export const generateLineRedirectUri = () => {
  const params = new URLSearchParams(location.search);
  const searchKeys = ['from', 'encrypt_data', 'promotion_id', 'channel_id'];
  const searchString = searchKeys
    .reduce((acc, key) => {
      let value = params.get(key);
      if (value) {
        // URLSearchParams will decode params once and line redirect will decode again.
        // encrypt_data contains special characters that need to be escaped.
        if (key === 'encrypt_data') {
          value = encodeURIComponent(value);
        }
        acc.push(`${key}=${value}`);
      }
      return acc;
    }, [])
    .join('&');

  const redirectPath = `${window.location.origin}/users/auth/line/setup`;

  return encodeURIComponent(
    `${redirectPath}${searchString ? `?${searchString}` : ''}`,
  );
};

export const getBenchatService = function (
  ajaxPackage,
  generateState = generateRandomNumber,
) {
  const state = generateState();

  return {
    /**
     * @param {string} userId
     * @param {array<string>} topics
     * @param {string} platform
     */
    getSubscriptions: function (userId, topics, platform) {
      const params = {
        userId,
        ...(topics && { topics }),
        ...(platform && { platform }),
      };
      return ajaxPackage.get('/api/benchat/subscriptions', {
        params,
        paramSerializer: '$httpParamSerializerJQLike',
      });
    },
    getLineActiveSubscriptions: function (topics, subscriptions) {
      return _.reduce(
        subscriptions,
        function (acc, subscription) {
          _.each(topics, function (topic) {
            if (
              subscription.isActive &&
              subscription.topic === topic &&
              subscription.platform === 'LINE_MES_API'
            ) {
              acc.push(subscription);
            }
          });
          return acc;
        },
        [],
      );
    },
    getFacebookActiveSubscriptions: function (topics, subscriptions) {
      return _.reduce(
        subscriptions,
        function (acc, subscription) {
          _.each(topics, function (topic) {
            if (
              subscription.isActive &&
              subscription.topic === topic &&
              subscription.platform === 'FACEBOOK'
            ) {
              acc.push(subscription);
            }
          });
          return acc;
        },
        [],
      );
    },
    deactivateSubscription: function (subscriptionId) {
      return ajaxPackage.put(
        '/api/benchat/subscriptions/' + subscriptionId + '/deactivate',
      );
    },
    generateUserRef: function (customerIdentifier) {
      // user-ref has to be unique on each rendering, so a random nonce is appended
      return `${customerIdentifier}-${generateState()}`;
    },
    getLineLoginUri: function (channelId) {
      const redirectUri = generateLineRedirectUri();
      const domain = 'https://access.line.me/oauth2/v2.1/authorize';

      return `${domain}?response_type=code&bot_prompt=aggressive&state=${state}&scope=profile openid email phone&client_id=${channelId}&redirect_uri=${redirectUri}`;
    },
    getLineMessageSubscriptionPopupUri: function (
      channelId,
      customerIdentifier,
      topic,
    ) {
      const query = this.generateLineQuery(customerIdentifier, 'POPUP', topic);
      return (
        'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&prompt=consent&bot_prompt=aggressive&state=' +
        state +
        '&scope=profile openid&client_id=' +
        channelId +
        '&redirect_uri=' +
        this.getLineOAuthRedirectUri(query)
      );
    },
    getLineMessageSubscriptionRedirectToUri: function (
      channelId,
      customerIdentifier,
      topic,
    ) {
      const query = this.generateLineQuery(
        customerIdentifier,
        'REDIRECT_TO',
        topic,
      );
      return (
        'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&prompt=consent&bot_prompt=aggressive&state=' +
        state +
        '&scope=profile openid&client_id=' +
        channelId +
        '&redirect_uri=' +
        this.getLineOAuthRedirectUri(query)
      );
    },
    generateLineQuery: function (customerIdentifier, action, topic) {
      const params = new URLSearchParams();
      params.append('user_ref', customerIdentifier);
      params.append('callback_action', action);
      params.append('uri', window.location.pathname + window.location.search);
      if (topic) {
        params.append('topic', topic);
      }
      return encodeURIComponent(params.toString());
    },
    getLineOAuthRedirectUri: function (query) {
      return `${window.location.origin}/oauth/line/callback?${query}`;
    },
    getLineQuickSignupUri: function (channelId) {
      return (
        'https://access.line.me/oauth2/v2.1/authorize?' +
        'response_type=code&bot_prompt=aggressive&state=' +
        state +
        '&scope=profile openid email phone&client_id=' +
        channelId +
        '&redirect_uri=' +
        window.location.origin +
        '/oauth/line/quick_sign_up_callback'
      );
    },
    getChannel: function (channelParams) {
      return ajaxPackage.get('/api/benchat/channel', { params: channelParams });
    },
    getRule: function (ruleParams) {
      return ajaxPackage.get('/api/benchat/rules', { params: ruleParams });
    },
    /**
     * @param {string} platform
     */
    activateSubscriptionRevamp: function (platform) {
      return ajaxPackage.put(
        `/api/benchat/subscriptions/platform/${platform}/activate`,
      );
    },
    /**
     * @param {array<{ topic: string; platform: string }>} subscriptions
     * @param {array<string>} topics
     * @param {string} platform
     */
    filterByPlatformAndTopics: function ({ subscriptions, platform, topics }) {
      return subscriptions.filter(
        (subscription) =>
          topics.includes(subscription.topic) &&
          subscription.platform === platform,
      );
    },
    /**
     * @param {array<{ topic: string; platform: string; userRef: string; isActive: boolean }>} subscriptions
     * @param {string} userRef
     */
    getSubscribeStatus: function (subscriptions, userRef) {
      const isFinished = subscriptions.every(
        (subscription) => subscription.userRef === userRef,
      );
      if (!isFinished || subscriptions.length === 0) {
        return SUBSCRIPTION_STATUS_TYPE.LOADING;
      }
      const isSuccess = subscriptions.every(
        (subscription) => subscription.isActive,
      );
      if (isSuccess) {
        return SUBSCRIPTION_STATUS_TYPE.SUCCESS;
      }
      const errorSubscription = subscriptions.find((subscription) =>
        Boolean(subscription.error),
      );
      return errorSubscription?.error;
    },
    getMDotMeLink: function (channelId = '', refOfParamInfo = '') {
      const domain = 'https://m.me';
      const ref = refOfParamInfo ? `?ref=${refOfParamInfo}` : '';

      return `${domain}/${channelId}${ref}`;
    },
  };
};

export default getBenchatService(axios);
