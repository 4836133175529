import { isObject } from 'lodash-es';

app.service('multiCheckoutService', [
  'mainConfig',
  '$http',
  '$filter',
  'slFeatureService',
  function (mainConfig, $http, $filter, slFeatureService) {
    const HTTP_HEADERS = {
      'X-Requested-With': 'XMLHttpRequest',
      cacheKey: mainConfig.currentPath,
    };

    const getMultiCheckoutForm = () =>
      $http({
        method: 'GET',
        url: '/cart/multi_cart_form',
        headers: HTTP_HEADERS,
      })
        .then(function (response) {
          return response.data;
        })
        .catch(() => {
          throw new Error(
            $filter('translate')('order_comment_and_message.general_error'),
          );
        });

    const updateMultiCheckoutForm = (payload) =>
      $http({
        method: 'PUT',
        url: '/cart/multi_cart_form',
        headers: HTTP_HEADERS,
        data: payload,
      })
        .then(function (response) {
          return response.data;
        })
        .catch(function () {
          throw new Error(
            $filter('translate')('order_comment_and_message.general_error'),
          );
        });

    const validateDelivery = () =>
      $http({
        method: 'POST',
        url: '/cart/validate_delivery',
        headers: HTTP_HEADERS,
        data: {},
      }).catch((errorResponse) => {
        const error = isObject(errorResponse?.data)
          ? { data: errorResponse?.data }
          : {
              data: {
                all: {
                  message: $filter('translate')(
                    'order_comment_and_message.general_error',
                  ),
                },
              },
            };
        throw error;
      });

    const getCartDestination = () =>
      $http({
        method: 'GET',
        url: '/cart/multi_cart_destination',
        headers: HTTP_HEADERS,
      })
        .then(function (response) {
          return response.data;
        })
        .catch(() => {
          throw new Error(
            $filter('translate')('order_comment_and_message.general_error'),
          );
        });

    const updateCartDestination = (payload) =>
      $http({
        method: 'PUT',
        url: '/cart/multi_cart_destination',
        headers: HTTP_HEADERS,
        data: payload,
      })
        .then(function (response) {
          return response.data;
        })
        .catch(function () {
          throw new Error(
            $filter('translate')('order_comment_and_message.general_error'),
          );
        });

    const saveSessionData = (payload) =>
      $http.post('/api/orders/session_data', payload);

    return {
      isEnabled: () => {
        return slFeatureService.hasFeature('multi_checkout');
      },
      isToggleOn: () => {
        return (
          slFeatureService.hasFeature('multi_checkout') &&
          !!mainConfig.merchantData.checkout_setting?.multi_checkout?.enabled
        );
      },
      getMultiCheckoutForm,
      updateMultiCheckoutForm,
      validateDelivery,
      getCartDestination,
      updateCartDestination,
      saveSessionData,
    };
  },
]);
