import './services.cookie-consent';
app.service('hiidoTrackerService', [
  '$cookies',
  '$location',
  '$injector',
  '$http',
  '$q',
  'mainConfig',
  'userAgentService',
  'slFeatureService',
  'cookieConsentService',
  function (
    $cookies,
    $location,
    $injector,
    $http,
    $q,
    mainConfig,
    userAgentService,
    slFeatureService,
    cookieConsentService,
  ) {
    var HIIDO_TRACKER_ROLLLOUT_KEY = 'yy_hiido_tracker';
    var HIIDO_PROD_ID = 'shopline';
    var HIIDO_REPORT_URL_KEY = 'hiidoReportUrl';
    var IS_FROM_EXPRESS_CHECKOUT_KEY = 'isFromExpressCheckout';

    var isProductionEnv = mainConfig.env === 'production';
    var path = $location.path();
    var searchs = $location.search();
    var events = {};
    var commonParams = {
      key2: mainConfig.requestCountry, // country
      key3: navigator.userAgent, // user-agent
      // key4:http_referer(by hiido)
      // key5:http_domain(by hiido)
      key7: path, // url-path
      key8: mainConfig.localeData.loadedLanguage.code, // language
      key9: userAgentService.isMobi() ? '101' : '102', // is_mobile
      key10: mainConfig.merchantId, // merchant_id
      key11: mainConfig.currentUser ? mainConfig.currentUser['_id'] : null, // user_id
      // key12: creat_at(by hiido)
      key14: window.location.search, // http-serarch
    };
    var eventConfig = {
      // frist events
      homepage: {
        eventId: 60006252,
        subEvents: {
          pageView: { key1: '101', key26: '101' },
        },
      },
      pdp: {
        eventId: 60006253,
        subEvents: {
          pageView: { key1: '101', key26: '102' },
          recommendProduct: { key1: '106' },
        },
      },
      orderEdit: {
        eventId: 60006254,
        subEvents: {
          pageView: { key1: '101', key26: '103' },
          updateItem: { key1: '103', key13: '102' },
          removeItem: { key1: '104', key13: '102' },
          proceedToCheckout: { key1: '107' },
        },
      },
      checkout: {
        eventId: 60006255,
        subEvents: {
          pageView: { key1: '101', key26: '104' },
          placeOrder: function () {
            if (searchs.page_id) {
              // mark current page type is「Express Checkout Pages」for order page
              sessionStorage.setItem(IS_FROM_EXPRESS_CHECKOUT_KEY, true);
            }
            return { key1: '108', key13: '101' };
          },
        },
      },
      transaction: {
        eventId: 60006256,
        subEvents: {
          pageView: function () {
            var params = { key1: '101', key25: '101', key26: '105' };
            var isFromExpressCheckout = sessionStorage.getItem(
              IS_FROM_EXPRESS_CHECKOUT_KEY,
            );
            if (isFromExpressCheckout) {
              sessionStorage.removeItem(IS_FROM_EXPRESS_CHECKOUT_KEY);
              params.key25 = '102';
            }
            return params;
          },
        },
      },
      callToAction: {
        eventId: 60006258,
        subEvents: {
          pageView: function () {
            if (searchs.sl_event_id && searchs.sl_target_id) {
              return { key1: '101', key13: '103', key26: '112' };
            }
          },
        },
      },
      productSearch: {
        eventId: 60006259,
        subEvents: {
          pageView: { key1: '101', key26: '106' },
        },
      },
      productList: {
        eventId: 60006260,
        subEvents: {
          pageView: { key1: '101', key26: '107' },
        },
      },
      expressCheckout: {
        eventId: 60006261,
        subEvents: {
          pageView: { key1: '101', key13: '104', key26: '110' },
          updateItem: { key1: '103', key13: '104' },
          checkout: { key1: '105', key13: '104' },
        },
      },
      cart: {
        eventId: 60006262,
        subEvents: {
          pageView: { key1: '101' },
          removeItem: { key1: '104', key13: '102' },
          checkout: { key1: '105' },
        },
      },
      addItem: {
        eventId: 60006263,
        subEvents: {
          pageView: { key1: '101' },
          addItem: { key1: '102', key13: '102' },
        },
      },
    };

    function getHttpCookie() {
      return $q(function (resolve) {
        if (!cookieConsentService.canUseCookie('marketing')) {
          return resolve(undefined);
        }

        var timer = setInterval(function () {
          var sptValue = $cookies.get('_spt');
          if (sptValue) {
            clearInterval(timer);
            resolve(sptValue);
          }
        }, 300);
      });
    }

    function getHiidoReportUrl() {
      return $q(function (reslove) {
        if (!cookieConsentService.canUseCookie('marketing')) {
          return reslove(undefined);
        }

        var hiidoReportUrl = sessionStorage.getItem(HIIDO_REPORT_URL_KEY);
        if (!hiidoReportUrl) {
          $http({
            method: 'GET',
            url: mainConfig.frontCommonsApi + '/conf/hiidoReportUrl',
          }).then(function (res) {
            if (res.data && res.data.success && res.data.data) {
              hiidoReportUrl = res.data.data;
              reslove(hiidoReportUrl);
              sessionStorage.setItem(HIIDO_REPORT_URL_KEY, hiidoReportUrl);
            }
          });
        } else {
          reslove(hiidoReportUrl);
        }
      });
    }

    function createReportEvent(eventId, baseParams) {
      return function reportEvent(params) {
        try {
          if (
            slFeatureService.hasFeature(HIIDO_TRACKER_ROLLLOUT_KEY) &&
            cookieConsentService.canUseCookie('marketing')
          ) {
            baseParams =
              typeof baseParams === 'function' ? baseParams() : baseParams;
            if (!baseParams) {
              return;
            }
            params = params || {};
            $q.all([getHttpCookie(), getHiidoReportUrl()]).then(
              function (data) {
                var hevent = new HiidoEvent(HIIDO_PROD_ID, eventId, {
                  debug: !isProductionEnv, // upload to hiido datatest env
                  isOversea: true,
                  reportUrl: data[1],
                });
                hevent.setRef(document.referrer);
                var values = Object.assign(
                  { key6: data[0] },
                  commonParams,
                  baseParams,
                  params,
                );
                hevent.setMoreinfo(values);
                hevent.reportJudge();
              },
            );
          }
        } catch (e) {
          console.error('hiddo tracker:', e);
        }
      };
    }

    Object.keys(eventConfig).forEach(function (eventName) {
      if (!events[eventName]) {
        events[eventName] = {};
      }
      var event = eventConfig[eventName];
      Object.keys(event.subEvents).forEach(function (subEventName) {
        events[eventName][subEventName] = createReportEvent(
          event.eventId,
          event.subEvents[subEventName],
        );
      });
    });

    function trackingWithRoute() {
      events.callToAction.pageView(); // tracking current page when from email;
      if (path === '/') {
        events.homepage.pageView();
      } else if (path === '/products') {
        if (searchs.query) {
          // tracking product searach page(/products?query=xxx)
          events.productSearch.pageView();
        } else {
          // tracking all products page(/products)
          events.productList.pageView({
            key34: '102',
          });
        }
      } else if (path.startsWith('/categories/')) {
        var categoryData = $injector.get('categoryData');
        var categoryKey = categoryData && categoryData.key;
        events.productList.pageView({
          key34: categoryKey === 'featured_products' ? '103' : '101',
        });
      } else if (
        path.indexOf('/pages/') > -1 &&
        $('body').is('.express-checkout-page')
      ) {
        // tracking express checkout page
        events.expressCheckout.pageView();
      }
    }

    function adaptCartEvents(action, item, options) {
      var productTypes = {
        product: '101',
        addon_product: '102',
        subscription_product: '103',
      };
      var params = {
        key19: productTypes[item.type],
        key20: item.product_id,
        key21: item.variation_id,
        key22: item.quantity,
        key23: item.price,
      };
      if (action === 'AddItem') {
        events.addItem.addItem(params);
      } else {
        var pageActions = {
          UpdateItem: 'updateItem',
          RemoveItem: 'removeItem',
        };
        var pageType = options.pageType;
        var trueAction = pageActions[action];
        if (
          events[pageType] &&
          events[pageType][trueAction] &&
          productTypes[item.type]
        ) {
          events[pageType][trueAction](params);
        }
      }
    }

    function adaptRecommendProduct(params) {
      // only tracking pdp recommend product
      if (path.indexOf('/products/') > -1) {
        events.pdp.recommendProduct({
          key16: params.based_product_id,
          key35: params.based_product_id,
          key36: params.product_id,
          key37: params.rank,
        });
      }
    }

    function adaptTransactionPageView() {
      var order = $injector.get('order');
      events.transaction.pageView({
        key38: order._id,
        key39: HIIDO_PROD_ID,
        key40: order.total && order.total.dollars,
        key41: order.order_delivery && order.order_delivery.total.dollars,
      });
    }

    return Object.assign(
      {
        trackingWithRoute: trackingWithRoute,
        adaptCartEvents: adaptCartEvents,
        adaptRecommendProduct: adaptRecommendProduct,
        adaptTransactionPageView: adaptTransactionPageView,
      },
      events,
    );
  },
]);
