app.service('gaService', [
  'Analytics',
  'mainConfig',
  function (Analytics, mainConfig) {
    this.impressions = [];

    this.addImpression = function (product) {
      this.impressions.push(product);
    };

    this.clearImpressions = function () {
      this.impressions = [];
    };

    this.sendPageView = function () {
      this.setUserId();
      Analytics.pageView();
      var length = this.impressions.length;
      if (length > 0) {
        _.each(this.impressions, function (product, index) {
          Analytics.addImpression(
            product['productId'],
            product['name'],
            product['list'],
            product['brand'],
            product['category'],
            product['variant'],
            index, //position
            product['price'],
          );

          if (index % 5 == 0 || index == length - 1) {
            Analytics.trackEvent(
              'Ecommerce',
              'Impressions',
              'Product Impressions',
              0,
              true,
            );
          }
        });
      }
    };

    this.setUserId = function () {
      if (mainConfig.currentUser) {
        Analytics.set('&uid', mainConfig.currentUser._id);
      }
    };
  },
]);
