app.service('merchantService', [
  '$rootScope',
  '$http',
  'mainConfig',
  '$filter',
  function ($rootScope, $http, mainConfig, $filter) {
    var merchantData = mainConfig.merchantData;
    var merchantId = merchantData._id;
    var merchantHandle = merchantData.handle;
    var deliveryOptions = null;
    var deliveryDependencies = {
      tw_ezship: 'ezship',
      tw_711_pay: 'tw_711_pay',
      tw_711_b2c_pay: 'tw_711_b2c_pay',
      tw_fm_b2c_pay: 'tw_fm_b2c_pay',
      tw_fm_c2c_pay: 'tw_fm_c2c_pay',
    };

    var paymentDependencies = {
      ezship: 'tw_ezship',
      tw_711_pay: 'tw_711_pay',
      tw_711_b2c_pay: 'tw_711_b2c_pay',
      tw_fm_b2c_pay: 'tw_fm_b2c_pay',
      tw_fm_c2c_pay: 'tw_fm_c2c_pay',
    };

    var isValidPaymentAndDelivery = function (payment, delivery) {
      var validDelivery = false;
      var deliveryDepArr = deliveryDependencies[delivery.region_type];
      if (deliveryDepArr) {
        if (!(deliveryDepArr instanceof Array)) {
          deliveryDepArr = [deliveryDepArr];
        }
        if ($.inArray(payment.type, deliveryDepArr) != -1) {
          validDelivery = true;
        }
      } else if (
        delivery.excluded_payment_ids &&
        delivery.excluded_payment_ids.length
      ) {
        if ($.inArray(payment._id, delivery.excluded_payment_ids) == -1) {
          validDelivery = true;
        }
      } else {
        // no dependency
        validDelivery = true;
      }

      var validPayment = false;
      var paymentDepArr = paymentDependencies[payment.type];
      if (paymentDepArr) {
        if (!(paymentDepArr instanceof Array)) {
          paymentDepArr = [paymentDepArr];
        }
        if ($.inArray(delivery.region_type, paymentDepArr) != -1) {
          validPayment = true;
        }
      } else if (
        payment.excluded_delivery_option_ids &&
        payment.excluded_delivery_option_ids.length
      ) {
        if (
          $.inArray(delivery._id, payment.excluded_delivery_option_ids) == -1
        ) {
          validPayment = true;
        }
      } else {
        validPayment = true;
      }

      return validPayment && validDelivery;
    };

    var removeInvalidOptions = function (
      srcArr,
      srcMatcher,
      targetArr,
      targetMatcher,
    ) {
      var invalidIndexArr = [];
      for (var i = srcArr.length - 1; i >= 0; i--) {
        // reversed direction to facilitate removing
        var src = srcArr[i];
        var depArr = srcMatcher(src); //deliveryDependencies[d.region_type]
        if (depArr) {
          // make an array if there is only 1 dependency
          if (!(depArr instanceof Array)) {
            depArr = [depArr];
          }
          var valid = false;
          angular.forEach(depArr, function (dep) {
            angular.forEach(targetArr, function (t) {
              if (dep == targetMatcher(t)) {
                valid = true;
                return;
              }
            });
            if (valid) {
              return;
            }
          });
          if (!valid) {
            invalidIndexArr.push(i);
          }
        }
      }
      angular.forEach(invalidIndexArr, function (i) {
        srcArr.splice(i, 1);
      });
    };

    var removeBlacklistedCheckoutOptions = function (
      deliveryOptions,
      payments,
      cartItems,
    ) {
      var blacklist = {
        paymentIds: [],
        deliveryOptionIds: [],
      };
      angular.forEach(
        cartItems,
        function (item) {
          if (
            item.product.blacklisted_payment_ids &&
            !_.isEmpty(item.product.blacklisted_payment_ids)
          ) {
            this.paymentIds = this.paymentIds.concat(
              item.product.blacklisted_payment_ids,
            );
          }
          if (
            item.product.blacklisted_delivery_option_ids &&
            !_.isEmpty(item.product.blacklisted_delivery_option_ids)
          ) {
            this.deliveryOptionIds = this.deliveryOptionIds.concat(
              item.product.blacklisted_delivery_option_ids,
            );
          }
        },
        blacklist,
      );
      removeInvalidOptions(
        payments,
        function (p) {
          return p._id;
        },
        _.difference(
          _.map(payments, function (p) {
            return p._id;
          }),
          blacklist.paymentIds,
        ),
        function (id) {
          return id;
        },
      );
      removeInvalidOptions(
        deliveryOptions,
        function (d) {
          return d._id;
        },
        _.difference(
          _.map(deliveryOptions, function (d) {
            return d._id;
          }),
          blacklist.deliveryOptionIds,
        ),
        function (id) {
          return id;
        },
      );
    };

    return {
      merchantId: merchantId,
      merchantHandle: merchantHandle,
      // setMerchantData: function(data) {
      //     merchantData = data;
      // },
      getCurrency: function () {
        return merchantData.base_currency;
      },

      fetchData: function () {
        $http({
          method: 'GET',
          url: '/api/theme/v1/merchants/' + this.merchantId,
        }).then(function (res) {
          merchantData = res.data.data;
          $rootScope.$broadcast('merchantService.update', merchantData);
        });
      },
      getDeliveryOptions: function () {
        if (deliveryOptions == null) return [];
        return deliveryOptions;
      },
      fetchDeliveryOptions: function () {
        $http({
          method: 'GET',
          url:
            '/api/theme/v1/merchants/' + this.merchantId + '/delivery_options',
        }).then(function (res) {
          deliveryOptions = res.data.data;
          $rootScope.$broadcast(
            'merchantService.deliveryOptions.update',
            deliveryOptions,
          );
        });
      },
      sanitizeCheckoutOptions: function (deliveryOptions, paymentMethods) {
        angular.forEach(deliveryOptions, function (option) {
          if (option.region_type == 'tw_ezship') {
            if (!option.form_fields) {
              option.form_fields = [];
            }
            option.form_fields = option.form_fields.concat([
              {
                key: 'recipient_name',
                name: $filter('translate')(
                  'orders.fields.delivery_data.' +
                    option.region_type +
                    '.recipient_name.label',
                ),
                label: $filter('translate')(
                  'orders.fields.delivery_data.' +
                    option.region_type +
                    '.recipient_name.label',
                ),
                hint: '',
                required: true,
                type: 'text',
              },
              {
                key: 'location_code',
                name: $filter('translate')(
                  'orders.fields.delivery_data.' +
                    option.region_type +
                    '.location_code.label',
                ),
                label: $filter('translate')(
                  'orders.fields.delivery_data.' +
                    option.region_type +
                    '.location_code.label',
                ),
                event: 'merchantService.deliveryOptions.showEzshipMap',
                hint: $filter('translate')(
                  'orders.fields.delivery_data.' +
                    option.region_type +
                    '.location_code.hint',
                ),
                required: true,
                type: 'button',
              },
            ]);
          }
        });
        removeInvalidOptions(
          paymentMethods,
          function (p) {
            return paymentDependencies[p.type];
          },
          deliveryOptions,
          function (d) {
            return d.region_type;
          },
        );
        removeInvalidOptions(
          deliveryOptions,
          function (d) {
            return deliveryDependencies[d.region_type];
          },
          paymentMethods,
          function (p) {
            return p.type;
          },
        );
      },
      validPaymentMethods: function (payments, deliveryOpt) {
        if (angular.isUndefined(deliveryOpt)) {
          return payments;
        }
        var arr = [];
        angular.forEach(payments, function (p) {
          if (isValidPaymentAndDelivery(p, deliveryOpt)) {
            arr.push(p);
          }
        });
        return arr;
      },
      removeBlacklistedCheckoutOptions: removeBlacklistedCheckoutOptions,
      isTrialPlan: function () {
        return mainConfig.merchantData.current_plan_key
          .split('_')
          .some(function (word) {
            return word === 'trial';
          });
      },
    };
  },
]);
