import './services.benchat';
app.service('fbService', [
  'mainConfig',
  '$http',
  '$q',
  'benchatService',
  'slFeatureService',
  function (mainConfig, $http, $q, benchatService, slFeatureService) {
    var platformChannelId;
    var triggerMessage = false;
    this.getLoginTriggerSetting = function () {
      if (
        !mainConfig.currentUser &&
        slFeatureService.hasFeature('fb_login_integration')
      ) {
        benchatService
          .getRule({ topic: 'SIGN_UP_MESSAGE' })
          .then(function (rule) {
            if (rule.data.length > 0) {
              var signUpMessageRule = _.filter(rule.data, {
                topic: 'SIGN_UP_MESSAGE',
              })[0];
              triggerMessage =
                signUpMessageRule.platformEnableSetting['FACEBOOK'];
            }
            return triggerMessage;
          })
          .then(function (trigger) {
            if (trigger) {
              benchatService
                .getChannel({ platform: 'FACEBOOK' })
                .then(function (response) {
                  platformChannelId = response.data.platformChannelId;
                });
            }
          });
      }
    };

    this.setSetting = function (trigger_message, platform_channel_id) {
      triggerMessage = trigger_message;
      platformChannelId = platform_channel_id;
    };

    this.login = function (appId, version) {
      var self = this;
      if (self.MerchantAppIdSet) {
        return $q(function (resolve) {
          var params;
          if (triggerMessage) {
            params = {
              scope: 'public_profile,email,user_messenger_contact',
              messenger_page_id: platformChannelId,
            };
          } else {
            params = { scope: 'public_profile,email' };
          }

          FB.login(function (response) {
            if (response.authResponse) {
              self.loginWithFacebookPayload(response).then(function () {
                resolve(response);
              });
            } else {
              resolve(response);
            }
          }, params);
        });
      }

      return $q(function (resolve) {
        self.getFacebookSetting().then(function (facebook_setting) {
          withMerchantAppId(facebook_setting['app_id'], version);
          var params;
          if (triggerMessage) {
            params = {
              scope: 'public_profile,email,user_messenger_contact',
              messenger_page_id: platformChannelId,
            };
          } else {
            params = { scope: 'public_profile,email' };
          }

          FB.login(function (response) {
            if (response.authResponse) {
              self
                .loginWithFacebookPayload(response)
                .then(function () {
                  resolve(response);
                })
                .finally(function () {
                  withSystemAppId(appId, version);
                });
            } else {
              withSystemAppId(appId, version);
              resolve(response);
            }
          }, params);
        });
      });
    };

    this.getFacebookLoginStatus = function (appId, version) {
      var self = this;
      return $q(function (resolve) {
        self.getFacebookSetting().then(function (user_setting) {
          withMerchantAppId(user_setting['app_id'], version);
          return FB.getLoginStatus(function (response) {
            resolve(response);
          });
        });
      });
    };

    this.loginWithFacebookPayload = function (facebookResponse) {
      return $http({
        method: 'POST',
        url: '/users/auth/facebook/callback',
        data: { signed_request: facebookResponse.authResponse.signedRequest },
      });
    };

    this.getFacebookSetting = function () {
      return $http
        .get('/api/merchants/' + mainConfig.merchantId + '/user_setting')
        .then(function (response) {
          return response.data['facebook_login'];
        });
    };

    this.init = function (version) {
      if (this.MerchantAppIdSet) {
        return $q.resolve();
      }

      this.getLoginTriggerSetting();

      var self = this;
      return self.getFacebookSetting().then(function (facebook_setting) {
        withMerchantAppId(facebook_setting['app_id'], version);
        self.MerchantAppIdSet = true;
      });
    };

    this.isMessengerInAppBrowser = function () {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /^(?=.*FB)(?=.*Messenger)/.test(userAgent);
    };

    this.setFacebookCustomerChatPosition = function () {
      function setGotoTopPosition() {
        var iconIframe = $('.fb_dialog iframe:first-child');
        var gotoTop = $('.sl-goto-top');
        if (gotoTop.length && iconIframe.length) {
          var top = iconIframe[0].getBoundingClientRect().top;
          var goToTopBottom = window.innerHeight - top + 15 + 'px';
          gotoTop.css('bottom', goToTopBottom);
        }
      }

      var promotionCart = $('.PromotionCart-panel.actived');
      var iconIframes = $('.fb_dialog iframe');
      if (iconIframes[0]) {
        if (promotionCart[0]) {
          iconIframes.addClass('with_promotion_cart');
        } else {
          iconIframes.removeClass('with_promotion_cart');
        }
      }
      setGotoTopPosition();
    };

    this.hideFacebookCustomerChat = function () {
      $('#fb-root').hide();
    };

    this.showFacebookCustomerChat = function () {
      $('#fb-root').show();
    };

    this.fbLogin = function (appId, version) {
      if (this.isMessengerInAppBrowser()) {
        return window.location.replace('/users/auth/facebook');
      }
      return this.login(appId, version);
    };

    function withMerchantAppId(app_id, version) {
      FB.init({
        appId: app_id,
        cookie: true,
        xfbml: true,
        version: version,
      });
    }

    function withSystemAppId(app_id, version) {
      FB.init({
        appId: app_id,
        xfbml: true,
        version: version,
      });
    }
  },
]);
